import * as model from './model.js';
import {} from './config.js';

import questionView from './views/questionView.js';
import { QuestionListView } from './views/questionListView.js';
import { DescriptionView } from './views/descriptionView.js';

/**
 * Initializes the application by loading the questions data,
 * populating the category dropdown, and setting the default stream of questions.
 */
const controlQuestions = function () {
  // Load the question data from the JSON file
  model.loadQuestionData();

  // Populate the dropdown with available categories
  populateDropdown();

  // Set the current stream of questions to "Všechny otázky" (default subcategory)
  const currentCategory = document.querySelector('#categoryDropdown').value;
  model.setCurrentStreamOfQuestions(currentCategory, 'Všechny otázky');

  // Render the first question from the current stream of questions
  const initialQuestion = model.state.currentStreamOfQuestions[0]?.text;
  if (initialQuestion) questionView.renderQuestion(initialQuestion);
};

/**
 * Populates the category dropdown menu with categories from the loaded question data.
 */
const populateDropdown = function () {
  const dropdown = document.querySelector('#categoryDropdown');
  const categories = Object.keys(model.state.categorizedQuestions);

  categories.forEach(category => {
    const option = document.createElement('option');
    option.value = category;
    option.textContent = category;
    dropdown.appendChild(option);
  });

  // Populate the subcategory buttons after categories are populated
  populateSubCategories();
};

/**
 * Handles the change in category selection from the dropdown menu.
 * Updates the stream of questions to the "Všechny otázky" subcategory of the selected category.
 */
const handleCategoryChange = function () {
  const selectedCategory = this.value;

  // Update the current stream of questions to the default "Všechny otázky" subcategory
  model.state.currentStreamOfQuestions =
    model.state.categorizedQuestions[selectedCategory]?.[
      'Všechny otázky'
    ].slice();

  // Render the first question from the updated stream of questions
  if (model.state.currentStreamOfQuestions.length > 0) {
    const initialQuestion = model.state.currentStreamOfQuestions[0].text;
    questionView.renderQuestion(initialQuestion);
  }

  // Populate the subcategories buttons for the new category
  populateSubCategories();
};

/**
 * Controls the rendering of the next question in the current stream of questions.
 */
const controlNextQuestion = function () {
  // Get the current question index
  const curIndex = model.getCurrentQuestionIndex();
  if (curIndex === model.state.currentStreamOfQuestions.length - 1) return; // Exit if it's the last question

  // Render the next question
  questionView.renderQuestion(
    model.state.currentStreamOfQuestions[curIndex + 1].text
  );
};

/**
 * Controls the rendering of the previous question in the current stream of questions.
 */
const controlBackQuestion = function () {
  // Get the current question index
  const curIndex = model.getCurrentQuestionIndex();
  if (curIndex === 0) return; // Exit if it's the first question

  // Render the previous question
  questionView.renderQuestion(
    model.state.currentStreamOfQuestions[curIndex - 1].text
  );
};

/**
 * Populates the subcategory buttons based on the selected category.
 * Also sets the "Všechny otázky" button as active by default.
 */
const populateSubCategories = function () {
  const selectedCategory = document.querySelector('#categoryDropdown').value;
  const subCategories = Object.keys(
    model.state.categorizedQuestions[selectedCategory]
  );

  questionView.renderSubCategories(subCategories);

  // Set "Všechny otázky" as active by default
  const InitialSubCategoryBtn = document.querySelector(
    '#subCategoryOptions button:first-child'
  );
  if (
    InitialSubCategoryBtn &&
    InitialSubCategoryBtn.textContent === 'Všechny otázky'
  ) {
    InitialSubCategoryBtn.classList.add('active');
  }
  // if všechny otátky is the only subcategory, hide the button

  if (InitialSubCategoryBtn.textContent === 'Všechny otázky') {
    InitialSubCategoryBtn.classList.add('hidden');
  }
};

/**
 * Handles the change of subcategory when a subcategory button is clicked.
 * Updates the active state of the buttons and renders the first question from the selected subcategory.
 */
const controlSubCategoryChange = function (e) {
  if (!e.target.matches('button')) return; // Exit if the clicked element is not a button

  // Remove the active class from the previously active button
  const activeButton = document.querySelector('#subCategoryOptions .active');
  if (activeButton) activeButton.classList.remove('active');

  // Add the active class to the clicked button
  e.target.classList.add('active');

  // Update the current stream of questions to the selected subcategory
  const currentCategory = document.querySelector('#categoryDropdown').value;
  const clickedSubCategory = e.target.textContent;
  model.state.currentStreamOfQuestions =
    model.state.categorizedQuestions[currentCategory][
      clickedSubCategory
    ].slice();

  // Render the first question from the updated stream of questions
  questionView.renderQuestion(model.state.currentStreamOfQuestions[0].text);
};

const controlModal = function () {
  const modalElement = document.getElementById('allQuestionsModal');
  const listElement = modalElement.querySelector('.modal-list');
  const titleElement = modalElement.querySelector('.modal-title');

  // Create an instance of QuestionListView
  const questionListView = new QuestionListView(
    modalElement,
    listElement,
    titleElement
  );

  // Show the modal and update its content when the "Show All Questions" button is clicked
  document
    .getElementById('showAllButton')
    .addEventListener('click', function () {
      // Update the list in the modal with currentStreamOfQuestions
      questionListView.updateList(model.state.currentStreamOfQuestions);

      // Update the modal title based on the selected category in the dropdown
      const selectedCategory =
        document.querySelector('#categoryDropdown').value;
      questionListView.updateTitle(selectedCategory);

      // Show the modal
      questionListView.openModal();
    });
};

const controlDescriptionModal = function () {
  const modalElement = document.getElementById('descriptionModal');

  // Create an instance of QuestionListView
  const descriptionView = new DescriptionView(modalElement);

  // Show the modal and update its content when the "Show All Questions" button is clicked
  document
    .getElementById('showDescription')
    .addEventListener('click', function () {
      // Show the modal
      descriptionView.openModal();
    });
};

/**
 * Initializes the application by setting up event handlers and loading initial data.
 */
const init = function () {
  controlQuestions();
  questionView.addNextButtonHandler(controlNextQuestion);
  questionView.addBackButtonHandler(controlBackQuestion);
  questionView.addCategoryChangeHandler(handleCategoryChange);
  questionView.addSubCategoryChangeHandler(controlSubCategoryChange);
  controlModal();
  controlDescriptionModal();
};

// Start the application
init();
