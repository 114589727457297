export class QuestionListView {
  constructor(modalElement, listElement, titleElement) {
    this.modalElement = modalElement;
    this.listElement = listElement;
    this.titleElement = titleElement;
    this.closeButton = this.modalElement.querySelector('.close-button');

    // Bind close button event
    this.closeButton.addEventListener('click', this.closeModal.bind(this));

    // Bind outside click event to close the modal
    window.addEventListener('click', event => {
      if (event.target === this.modalElement) {
        this.closeModal();
      }
    });

    // Bind Escape key to close the modal
    window.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        this.closeModal();
      }
    });
  }

  openModal() {
    this.modalElement.classList.remove('hidden');
  }

  closeModal() {
    this.modalElement.classList.add('hidden');
  }

  updateList(questions) {
    this.listElement.innerHTML = '';
    questions.forEach(question => {
      const li = document.createElement('li');
      li.textContent = question.text;
      this.listElement.appendChild(li);
    });
  }

  updateTitle(category) {
    this.titleElement.textContent = category;
  }
}
