export class DescriptionView {
  constructor(modalElement) {
    this.modalElement = modalElement;
    this.closeButton = this.modalElement.querySelector('.close-button');

    // Bind close button event
    this.closeButton.addEventListener('click', this.closeModal.bind(this));

    // Bind outside click event to close the modal
    window.addEventListener('click', event => {
      if (event.target === this.modalElement) {
        this.closeModal();
      }
    });

    // Bind Escape key to close the modal
    window.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        this.closeModal();
      }
    });
  }

  openModal() {
    this.modalElement.classList.remove('hidden');
  }

  closeModal() {
    this.modalElement.classList.add('hidden');
  }
}
